<template>
  <div class="attribute">
    <div>
      <slot name="name" />
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  computed: {},
  data: () => ({}),
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.attribute {
  > div:first-child {
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    color: #999999;
  }
  > div:nth-child(2) {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
  }
}
</style>
