<template>
  <v-row class="payment-filters">
    <v-col cols="12" class="py-0">
      <datatable
        :headers="headers"
        :table="payments"
        headerless
        class="v-data-table--in-row-headers"
      >
        <template #[`item.name`]="{ item }">
          <span>{{ $t("clinic") }}</span>
          <span>{{ item.name }}</span>
        </template>
        <template #[`item.status`]="{ item }">
          <span>{{ $t("email") }}</span>
          <span>{{ item.manager_email }}</span>
        </template>
        <template #[`item.created_by`]="{ item }">
          <span>{{ $t("registered-by") }}</span>
          <span>{{ item.created_by }}</span>
        </template>
        <template #[`item.id`]="{ item }">
          <div
            class="payment-buttons svg-buttons svg-buttons--4"
            :data-id="item.id"
            style="white-space: nowrap"
          >
            <v-btn
              small
              icon
              class="ml-2 pl-0 pr-0"
              @click="$router.push({ name: 'clinics-details', params: { id: item.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/details.svg`)" />
            </v-btn>
            <change-password-dialog :item="item.user" />
            <v-btn
              small
              icon
              color="success"
              class="pl-0 pr-0"
              @click="$router.push({ name: 'clinics-edit', params: { id: item.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit.svg`)" />
            </v-btn>
            <remove-dialog :item="item" @removed="reload" />
          </div>
        </template>
      </datatable>
    </v-col>
  </v-row>
</template>

<script>
import Datatable from "../components/Datatable";
import ChangePasswordDialog from "../components/ChangePasswordDialog";
import clinics from "../../requests/clinics";
import RemoveDialog from "./RemoveDialog";

export default {
  components: {
    Datatable,
    RemoveDialog,
    ChangePasswordDialog,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
    day: {
      type: Object,
      default: null,
    },
    creator: {
      type: Object,
      default: null,
    },
  },
  mixins: [],
  data: () => ({
    payments: {
      filters: {
        status: [-1],
        type: [-1],
        scheduled_from: null,
        scheduled_to: null,
      },
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
  }),
  computed: {
    headers: function () {
      return [
        {
          text: "",
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: "",
          value: "status",
          sortable: false,
        },
        {
          text: "",
          value: "created_by",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  watch: {},
  created: async function () {
    this.reload();
  },
  methods: {
    reload: async function () {
      const params = {};
      if (this.creator) {
        params.creator = this.creator.id;
      }
      const response = await clinics.load(params);
      this.payments.items = response.items;
      this.payments.totalItems = response.total;
    },
  },
};
</script>

<style lang="scss" scoped></style>
